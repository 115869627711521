import { useState, useEffect } from 'react'
import './FacetedSelect.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import useOutsideClick from '../../hooks/useOutsideClick.js'

const FacetedSelect = ({
  label,
  value,
  onChange,
  options = [],
  placeholder = 'Kies een optie',
  className = '',
  optionType = 'list',
  width
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focussed, setFocussed] = useState(options.findIndex(option => option.label === value))
  // const [count, setCount] = useState(options.find(option => option.label === value)?.count)
  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  useEffect(() => {
    // const y = window.scrollY

    // function handleScrollEvent (e) {
    //   window.scrollTo(0, y)
    // }

    // function handleWheelEvent () {
    //   setIsOpen(false)
    // }

    // if (isOpen) {
    //   window.addEventListener('scroll', handleScrollEvent)
    //   window.addEventListener('wheel', handleWheelEvent)
    //   window.addEventListener('touchmove', handleWheelEvent)
    // }
    // return () => {
    //   window.removeEventListener('scroll', handleScrollEvent)
    //   window.removeEventListener('wheel', handleWheelEvent)
    //   window.removeEventListener('touchmove', handleWheelEvent)
    // }
  }, [isOpen])

  useEffect(() => {
    function handleWindowClick (e) {
      if (!e.target.closest('.FacetedSelect')) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      window.addEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen])

  useEffect(() => {
    function handleKeyPress (e) {
      switch (e.code) {
        case 'ArrowDown': {
          if (!options.some(option => !option.disabled)) return // make sure at least one option is available

          let newFocussed = (focussed + 1 + options.length) % options.length
          while (true) {
            const option = options[newFocussed]
            if (!option.disabled) {
              setFocussed(newFocussed)
              break
            } else {
              newFocussed = (newFocussed + 1 + options.length) % options.length
            }
          }
          break
        }
        case 'ArrowUp': {
          if (!options.some(option => !option.disabled)) return // make sure at least one option is available

          let newFocussed = (focussed - 1 + options.length) % options.length
          while (true) {
            const option = options[newFocussed]
            if (!option.disabled) {
              setFocussed(newFocussed)
              break
            } else {
              newFocussed = (newFocussed - 1 + options.length) % options.length
            }
          }
          break
        }
        case 'Escape': {
          setIsOpen(false)
          break
        }
        // falls through
        case 'Space':
        case 'Enter': {
          onChange(options[focussed].label)
          setIsOpen(false)
          break
        }
        default:
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isOpen, focussed, onChange, options])

  function handleOptionClick (option) {
    return () => {
      onChange(option.label)
      // setCount(option.count)
      setIsOpen(false)
    }
  }

  const selectedOption = value && options.find(option => option.label === value)

  const getOptions = () => {
    switch (optionType) {
      case 'list' :
        return (
          <div className='FacetedSelect-options'>
            <Option
              key={`${label}_empty`}
              label='--'
              onSelect={handleOptionClick({ label: '' })}
              selected={false}
              focussed={focussed === -1}
              setFocussed={() => setFocussed(-1)}
            />
            {options.map((option, i) => {
              return (
                <Option
                  key={`${label}_${i}`}
                  disabled={option.disabled}
                  label={option}
                  onSelect={handleOptionClick(option)}
                  selected={value === option.label}
                  focussed={focussed === i}
                  setFocussed={() => setFocussed(i)}
                />
              )
            })}
          </div>
        )
      case 'blockList' :
        return (
          <div className='FacetedSelect-blockList-options' style={{}}>
            {options.map((option, i) => {
              return (
                <BlockOption
                  key={`${label}_${i}`}
                  disabled={option.disabled}
                  label={option}
                  onSelect={handleOptionClick(option)}
                  selected={value === option.label}
                  focussed={focussed === i}
                  setFocussed={() => setFocussed(i)}
                />
              )
            })}
            {value !== '' && (
              <ClearOption
                key={`${label}_empty`}
                onSelect={handleOptionClick({ label: '' })}
                selected={false}
                focussed={focussed === -1}
                setFocussed={() => setFocussed(-1)}
              />)}
          </div>
        )
      default :
        return null
    }
  }

  const Options = getOptions()
  return (
    <div className={`FacetedSelect ${className}`} ref={ref}>
      {label && <label className='FacetedSelect-label'>{label}</label>}
      <select value={value} onChange={e => onChange(e.target.value)}>{options.map((option, i) => <option key={`${label}_${i}`} value={option.value}>{option.label}</option>)}</select>
      <div className={`FacetedSelect-current ${isOpen ? 'FacetedSelect-current-active' : ''} ${label ? 'FacetedSelect-small' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption
          ? <p>{selectedOption.label} ({selectedOption.count})</p>
          : <p>{placeholder}</p>}
        <Icon className='FacetedSelect-current-caret' name='caretDown' width={7} height={7} fill='var(--dark-grey)' />
      </div>
      {isOpen && Options}
    </div>
  )
}

const Option = ({ disabled, label, onSelect, selected, customLabel, focussed, setFocussed }) => {
  const myClasses = ['FacetedSelect-option']
  if (focussed) myClasses.push('FacetedSelect-option-focussed')
  if (disabled) myClasses.push('FacetedSelect-option-disabled')
  return (
    <div className={myClasses.join(' ')} onClick={disabled ? () => {} : onSelect} onMouseEnter={disabled ? () => {} : () => setFocussed()}>
      {Object.hasOwnProperty.call(label, 'count')
        ? <p>{label.label} ({label.count})</p>
        : <p>--</p>}
      {selected && <Icon className='Select-option-check' name='check' fill='var(--green)' />}
    </div>
  )
}

const BlockOption = ({ disabled, label, onSelect, selected, customLabel, focussed, setFocussed }) => {
  const myClasses = ['FacetedSelect-blockOption']
  if (focussed) myClasses.push('FacetedSelect-blockOption-focussed')
  if (disabled) myClasses.push('FacetedSelect-blockOption-disabled')
  return (
    <div className={myClasses.join(' ')} onClick={disabled ? () => {} : onSelect} onMouseEnter={disabled ? () => {} : () => setFocussed()}>
      {Object.hasOwnProperty.call(label, 'count')
        ? <p>{label.label} ({label.count})</p>
        : <p>--</p>}
      {selected && <Icon className='Select-blockOption-check' name='check' fill='var(--green)' />}
    </div>
  )
}

const ClearOption = ({ disabled, onSelect, selected, customLabel, focussed, setFocussed }) => {
  const myClasses = []
  if (disabled) myClasses.push('FacetedSelect-blockOption-disabled')
  return (
    <div className={myClasses.join(' ')} onClick={disabled ? () => {} : onSelect} onMouseEnter={disabled ? () => {} : () => setFocussed()}>
      <div className='Input-status'>
        <div className='Input-clear'>
          <button tabIndex={-1} type='button'>
            <Icon name='cross' fill='var(--super-dark-grey)' />
          </button>
        </div>
      </div>
    </div>
  )
}

FacetedSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default FacetedSelect
