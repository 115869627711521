import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

const getJatoFacets = ({ brand, exsid, model, modelyear, fuel, gearbox, version, versionContainsAnd, cc, kw, co2 }) => {
  const searchParams = {
    source: 'jato',
    faceted: true,
    exsid,
    ...(modelyear !== '' && { modelyear }),
    ...(fuel !== '' && { fuel }),
    ...(gearbox !== '' && { gearbox }),
    ...(version !== '' && { version }),
    ...(versionContainsAnd !== '' && { versionContainsAnd: versionContainsAnd.trim().replaceAll(' ', ',') }),
    ...(cc !== '' && { cc }),
    ...(kw !== '' && { kw }),
    ...(co2 !== '' && { co2 }),
    size: 10000
  }

  return ky.get(`${getApiHost()}/v1/${brand}/${model}/facets`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token')
    },
    searchParams,
    timeout: false
  })
}

export default getJatoFacets
